import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import { Flex, Text } from "rebass"
import qs from "qs"

import { useAuth } from "../../context/auth"
import LoginLayout from "../../components/login-layout"
import SignupForm from "../../components/signup-form"
import Spinner from "../../components/spinner"

import MedusaClient from "../../services/api"
import { trackSignUp } from "../../services/analytics"
import { AccountContext } from "../../context/account"

const SignupPage = () => {
  const [loading, setLoading] = useState(false)

  const auth = useAuth()
  const { handleCreateAccount } = useContext(AccountContext)

  const handleSignup = async values => {
    setLoading(true)
    return await auth
      .handleSignup(
        "email",
        {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password
        },
        {
          company: values.name,
          role: values.role,
          industry: values.industry
        }
      )
      .then(async () => {
        const [, ...search] = location.search
        const { next, cli_tok, req_tok } = qs.parse(search)
        if (next === "cli" && cli_tok && req_tok) {
          await MedusaClient.cliAuth.authenticate(cli_tok, req_tok)
          navigate("/cli-login/done")
        } else {
          await handleCreateAccount({
            name: values.name,
            billing_email: values.email
          })
          navigate("/a")
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <LoginLayout pt={3} title={"Sign Up"}>
      <Text mb={4} fontWeight="bold" fontSize={4}>
        Create an account
      </Text>
      {loading ? (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      ) : (
        <SignupForm onSignup={handleSignup} />
      )}
    </LoginLayout>
  )
}

export default SignupPage
